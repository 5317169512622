import React from "react";
import { motion } from "framer-motion";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { slideUpVariants } from "../CustomHooks/animations/animation";

const Contact = () => {
  return (
    <div className="bg-white py-20">
      <div className="max-w-6xl mx-auto px-6 text-center">
        <motion.h1
          variants={slideUpVariants}
          className="text-4xl font-bold text-black mb-4"
        >
          Contact Us
        </motion.h1>
        <div className="w-24 h-1 bg-orange-500 mx-auto mb-6"></div>

        <p className="text-lg text-gray-700 mb-10">
          We’d love to hear from you. Here’s how you can get in touch with us.
        </p>

        <div className="grid lg:grid-cols-3 gap-12">
          {/* Address Section */}
          <div className="flex flex-col items-center text-center">
            <FaMapMarkerAlt className="text-4xl text-orange-500 mb-4" />
            <h3 className="text-xl font-semibold text-black mb-2">Our Address</h3>
            <p className="text-gray-700 text-lg">
            HNO: 5-15-Ti0011, Ground Floor,   <br />
            Tirumala Nagar, Almasguda, Balapur Mandal, Rangareddy Dist,Telangana, India-500112
            </p>
          </div>

          {/* Phone Section */}
          <div className="flex flex-col items-center text-center">
            <FaPhoneAlt className="text-4xl text-orange-500 mb-4" />
            <h3 className="text-xl font-semibold text-black mb-2">Call Us</h3>
            <p className="text-gray-700 text-lg">
              +91 78423 89502
            </p>
          </div>

          {/* Email Section */}
          <div className="flex flex-col items-center text-center">
            <FaEnvelope className="text-4xl text-orange-500 mb-4" />
            <h3 className="text-xl font-semibold text-black mb-2">Email Us</h3>
            <p className="text-gray-700 text-lg">
              info@shriarc.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
