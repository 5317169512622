import React from "react";
import { motion } from "framer-motion";
import { slideUpVariants } from "../CustomHooks/animations/animation";
import { projects } from "../../export";

const Products = () => {
  return (
    <div id="product" className="w-full">
      <motion.div
        initial="hidden"
        whileInView="visible"
        // variants={slideUpVariants}
        className="w-full m-auto py-[60px] flex flex-col justify-center items-center gap-[20px]"
      >
       
        <motion.h1 variants={slideUpVariants} className="text-black text-[40px] font-bold">
          TOP SELLING PRODUCTS
        </motion.h1>
        <div className="w-[120px] h-[6px] bg-orange-500"></div>

        <div className="w-full grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 mt-[30px]">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              // variants={zoomInVariants}
              className="bg-white w-[300px] shadow-lg rounded-2xl overflow-hidden flex flex-col items-center transform hover:scale-105 transition-transform duration-300"
            >
              <div className="relative w-full h-[200px] p-2 ">
                <img
                  src={project.image}
                  alt="Project"
                   className=" w-full h-[200px]  "
                />
              </div>
              <div className="p-4 w-full">
                <h2 className="text-black text-center text-lg font-bold">
                  {project.title}
                </h2>
                <p className="text-gray-500 text-center text-sm mt-2">{project.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Products;
